import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  Button,
} from "@mui/material";
import Slider from "react-slick";
import Rating from "react-rating";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { FaPlayCircle } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import AxiosInstance from "../../../config/AxiosInstance";
import GalleryImage2 from "../../../Assets/Images/productgallery2.png";
import DoubleQ from "../../../Assets/Images/quet.png";
import coralBackground from "../../../Assets/Images/Container.png";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductDetails.scss";
const ProductDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState([]);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [contentHeight, setContentHeight] = useState(0);
  const [customersList, setCustomersList] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.productId) {
      getProductDetails(location?.state?.productId);
      getCustomers();
    }
  }, [location?.state?.productId]);
  const getProductDetails = async (productId) => {
    try {
      setTimeout(async () => {
        const res = await AxiosInstance.get(
          `/product/get-by-id?product_id=${productId}`
        );
        if (res && res.data && res.data.s === 1) {
          const productMedia = res.data.r.product_media;
          const sortedMedia = productMedia.sort((a, b) => {
            if (a.type === 2) return -1;
            if (b.type === 2) return 1;
            return 0;
          });
          setProductDetails({ ...res.data.r, product_media: sortedMedia });
          setIsLoading(false);
        }
      }, 2000);
    } catch (error) {
      console.log("Error fetching product details:", error);
      setIsLoading(false);
    }
  };
  const getCustomers = async () => {
    try {
      const res = await AxiosInstance.get("/web-management/get-testimonials");
      if (res && res?.data?.s === 1) {
        setCustomersList(res?.data?.r);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    const calculateContentHeight = () => {
      const headerHeight = 80;
      const footerHeight = 10;
      const totalHeight = window.innerHeight - headerHeight - footerHeight;
      setContentHeight(totalHeight);
    };

    calculateContentHeight();
    window.addEventListener("resize", calculateContentHeight);
    return () => {
      window.removeEventListener("resize", calculateContentHeight);
    };
  }, []);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoPlay: true,
    loop: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Hide arrows when screen width is less than or equal to 768 pixels
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <Fragment>
      <div className="productDetails">
      <Container fluid>
          <Button
            type="button"
            className="backButton"
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack color="#ffffff" fontSize={"32px"} />
          </Button>
          <Row>
            {isLoading ? (
              <Col xs={12}>
                <div className="isLoading" style={{ height: contentHeight }}>
                  <Spinner size="lg" color="light" />
                </div>
              </Col>
            ) : (
              <Col xs={12}>
                <Row>
                  {productDetails &&
                    productDetails?.product_media?.map((media, index) => {
                      return (
                        <>
                          {media?.type === 2 && (
                            <Col key={index} xs={12}>
                              <div className="productDetailsVideo">
                                <div className="productDetailsVideoInner">
                                  <video
                                    autoPlay={isVideoPlaying}
                                    muted
                                    loop
                                    className="productBgVideo"
                                  >
                                    <source
                                      src={media?.media}
                                      type="video/mp4"
                                    />
                                  </video>
                                  {!isVideoPlaying && (
                                    <Button
                                      onClick={() => {
                                        setIsVideoPlaying(true);
                                        setModalOpen(true);
                                      }}
                                      className="playButton"
                                    >
                                      <FaPlayCircle
                                        color="#ffffff"
                                        fontSize={"42px"}
                                      />
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </Col>
                          )}
                          {media?.type === 1 && (
                            <Col xs={12} md={6} lg={4} xl={4} xxl={3} className="mb-3">
                              <Card>
                                <CardBody>
                                  <div className="productImage">
                                    <img
                                      src={media?.media}
                                      className="img-fuild"
                                      alt="product"
                                    />
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          )}
                        </>
                      );
                    })}
                </Row>
              </Col>
            )}
          </Row>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: { xs: "24px", md: "60px" },
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Box
                sx={{
                  padding: "10px 0px",
                  height: "100%",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    lineHeight: "45px",
                    fontWeight: "500",
                    fontSize: { xs: "24px", lg: "32px" },
                    fontFamily: "Sansation",
                    color: "#DDD",
                  }}
                  variant="h6"
                >
                  Designed for Corporate-gifting and customisation.
                </Typography>
                <Typography
                  sx={{
                    lineHeight: "24px",
                    fontWeight: "400",
                    fontSize: "16px",
                    fontFamily: "Sansation",
                    color: "#DDD",
                    marginTop: "20px",
                  }}
                  variant="h6"
                >
                  Lorem ipsum dolor sit amet consectetur. In nunc cursus quis
                  consectetur ornare risus odio ornare porttitor. Vivamus velit
                  donec diam quam leo arcu. Nulla lacus vel egestas tellus
                  tristique congue nunc. Amet nulla ante nunc enim sapien
                  tortor. Nascetur varius in ut ac egestas sagittis ultricies
                  sit. Lobortis placerat augue pretium et. Eget eleifend
                </Typography>
                <Button
                  sx={{
                    marginTop: "24px",
                    borderRadius: "30px",
                    background: "#46adf2",
                    color: "#fff",
                    textAlign: "center",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                    width: "200px",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Sansation",
                    "&:hover": {
                      background: "#3c8dc5",
                    },
                  }}
                  onClick={() =>
                    window.open("https://portal.roavebox.com", "_blank")
                  }
                >
                  Join Now
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Box sx={{ width: "100%", height: "408px" }}>
                <img
                  src={GalleryImage2}
                  alt="images"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                    height: "100%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          className="getStarted"
          sx={{
            backgroundImage: `url(${coralBackground})`,
            backgroundPosition: "cente center",
            backgroundSize: "100% 100%",
            height: "100%",
            width: "100%",
            padding: "130px 0px",
            marginTop: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: { xs: "24px", lg: "32px" },
              fontFamily: "Sansation",
              color: "#DDD",
              marginTop: "20px",
            }}
            variant="h6"
          >
            Create your account today and get started
          </Typography>
          <Button
            sx={{
              marginTop: "24px",
              borderRadius: "30px",
              background: "#46adf2",
              color: "#fff",
              textAlign: "center",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
              width: "200px",
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Sansation",
              "&:hover": {
                background: "#3c8dc5",
              },
            }}
            onClick={() => window.open("https://portal.roavebox.com", "_blank")}
          >
            Join Now
          </Button>
        </Box>
        <Box className="productGalleryDetails">
        <Container fluid>
          <Box className="productContainer">
            <Box className="productGalleryList">
              <Box className="customar">
                <Typography
                  variant="h3"
                  sx={{
                    padding: "50px 0px",
                    lineHeight: "24px",
                    fontSize: "32px",
                    fontWeight: "500",
                    color: "#ddd",
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "Sansation",
                  }}
                >
                  What Our Customers Says?
                </Typography>
                <Slider {...settings} className="customersSlider">
                  {customersList &&
                    customersList?.map((slide, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            backgroundColor: "#4D4E4E",
                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                            borderRadius: "26px",
                            padding: "29px 30px",
                          }}
                        >
                          <img src={DoubleQ} alt="" />
                          <Typography
                            variant="body2"
                            sx={{
                              lineHeight: "24px",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#ddd",
                              width: "100%",
                              fontFamily: "Sansation",
                              padding: "19px 0px",
                            }}
                          >
                            {slide?.review}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#3EACF5",
                              padding: "6px 21px 6px 7px",
                              borderRadius: "59px",
                              width: { xs: "100%", md: "100%", lg: "100%" },
                            }}
                          >
                            <img
                              src={slide?.customer_img}
                              alt="customers"
                              style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                            <Box
                              sx={{
                                marginLeft: "10px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "500px",
                                  color: "#ddd",
                                  fontFamily: "Sansation",
                                  textTransform: "uppercase",
                                }}
                              >
                                {slide?.name}
                              </Typography>
                              <Box>
                                <Rating
                                  emptySymbol={
                                    <StarOutlineIcon
                                      sx={{ fontSize: "22px", color: "gold" }}
                                    />
                                  }
                                  fullSymbol={
                                    <StarIcon
                                      sx={{ fontSize: "22px", color: "gold" }}
                                    />
                                  }
                                  size={6}
                                  initialRating={slide?.rating}
                                  readonly={true}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                </Slider>
              </Box>
            </Box>
          </Box>
          </Container>
        </Box>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setIsVideoPlaying(false);
          setModalOpen(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "0 0 10px rgba(0,0,0,0.9)",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "70%",
              margin: "auto",
              height: "70%",
              position: "relative",
              padding: "24px",
            }}
          >
            {productDetails &&
              productDetails.product_media &&
              productDetails.product_media.length > 0 && (
                <video
                  autoPlay={isVideoPlaying}
                  muted
                  loop
                  controls
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "100%",
                    borderRadius: "10px",
                    objectPosition: "center",
                  }}
                >
                  <source
                    src={productDetails.product_media[0].media}
                    type="video/mp4"
                  />
                </video>
              )}
            <IconButton
              sx={{
                position: "absolute",
                top: "0",
                right: "0",
                backgroundColor: "#ffffff",
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
              }}
              onClick={() => {
                setIsVideoPlaying(false);
                setModalOpen(false);
              }}
            >
              <AiOutlineClose color="#000" fontSize={"32px"} />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default ProductDetails;

import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { BiX } from "react-icons/bi";
import { Button, Container } from "reactstrap";
import LOGO from "../../Assets/Images/logo.png";
import "./Header.scss";
const Header = () => {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  const navLinks = [
    { path: "/home", text: "Home" },
    { path: "/product", text: "Product Gallery" },
    { path: "/contact-us", text: "Contact Us" },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setToggle(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <header className="header">
      <Container fluid>
        <div className="headerRow">
          <div className="headerColumnLeft">
            <div
              className="logoColumn"
              onClick={() => navigate("/home")}
              style={{
                cursor: "pointer",
              }}
            >
              <img src={LOGO} alt="logo" className="logo img-fuild" />
            </div>
          </div>
          <div
            ref={menuRef}
            className={
              !toggle
                ? "headerColumnCenter"
                : "headerColumnCenter activeNavbarWrapper"
            }
          >
            <div className={"navbarWrapper"}>
              <ul>
                {navLinks.map((link, index) => (
                  <li key={index}>
                    <NavLink
                      to={link.path}
                      activeClassName="navActive"
                      className="navLinks"
                      onClick={() => setToggle(false)}
                    >
                      {link.text}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="headerColumnRight">
            <Button
              type="button"
              className="joinUsButton"
              onClick={() =>
                window.open("https://portal.roavebox.com", "_blank")
              }
            >
              Join Us
            </Button>
            <Button
              type="button"
              className="toggleMenuDiv"
              onClick={() => setToggle(!toggle)}
            >
              {!toggle ? <BiMenu /> : <BiX />}
            </Button>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;

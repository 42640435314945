import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import AxiosInstance from '../../config/AxiosInstance';
import './CodeOfConduct.scss';

const CodeOfConduct = () => {
    const [codeOfConductList, setCodeOfConductList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        const getProduct = async () => {
            try {
                const res = await AxiosInstance.get("/web-management/get-general-docs");
                if (res && res?.data?.s === 1) {
                    setCodeOfConductList(res?.data?.r);
                }
            } catch (error) {
                console.log("error", error);
            } finally {
                setIsLoading(false);
            }
        };
        getProduct();
    }, []);
    return (
        <Fragment>
            <div className='codeofConduct'>
            <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className='mainTitleSection'>
                                <h5>Code Of Conduct</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        { isLoading 
                        ? <Col xs={12}>
                            <div className='isLoading'>
                                <Spinner size="lg" color="light"/>
                            </div>
                        </Col> 
                        : codeOfConductList?.length > 0
                            ? codeOfConductList?.map((item, index) => {
                                return (
                                    <Col xs={12} md={12} xl={12} xxl={12} key={index} data-aos="fade-right">
                                        <Card>
                                            <CardBody>
                                            <div dangerouslySetInnerHTML={{ __html: item?.code_of_conduct }} style={{ color: 'white' }} />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            })
                            : <Col xs={12}>
                                <div className='notDataFound'>
                                    <h6>No Data Found</h6>
                                </div>
                            </Col>}
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}

export default CodeOfConduct;

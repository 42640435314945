import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import CustomTextField from '../../Components/CustomTextField/CustomTextField';
import AxiosInstance from '../../config/AxiosInstance';
import LoaderButton from '../../Components/LoaderButton/LoaderButton';
import './ContactUs.scss';
const ContactUs = () => {
  const [busy, setBusy] = useState(false);
  useEffect(() =>{
    window.scrollTo(0, 0);
  }, [])
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This Field Is Required"),
      email: Yup.string()
        .email("Please Enter valid Email Address")
        .required("This Field Is Required"),
      message: Yup.string().required("This Field Is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (busy) return;
      setBusy(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("message", values.message);
      try {
        const response = await AxiosInstance.post('/contact/add-contact-us', formData);
        if (response?.data?.s) {
          toast.success(response?.data?.m);
        } else {
          toast.error(response?.data?.m);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error("404 Error: Page not found");
        } else {
          // Handle other errors
        }
      } finally {
        setBusy(false);
        resetForm();
      }
    },
    
  });
  return (
    <Fragment>
      <section className='contactUs'>
        <div className='contactUsBg' loading='lazy'></div>
        <Container fluid="xl">
          <Card>
            <CardBody>
              <Form onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}>
                <Row>
                  <Col xs={12}>
                    <div className="titleSection">
                      <h5 className="title">Contact us</h5>
                      <p>We want to hear from you</p>
                    </div>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <CustomTextField
                      type="text"
                      placeholder="Name"
                      name="name"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      onKeyUp={formik.handleBlur}
                      invalid={formik.touched.name && formik.errors.name}
                      errorMessage={formik.errors.name}
                    />
                  </Col>
                  <Col xs={12} className="mb-3">
                    <CustomTextField
                      type="text"
                      placeholder="Email"
                      name="email"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onKeyUp={formik.handleBlur}
                      maxLength={350}
                      invalid={formik.touched.email && formik.errors.email}
                      errorMessage={formik.errors.email}
                    />
                  </Col>
                  <Col xs={12} className="columnInput">
                    <CustomTextField
                      type="textarea"
                      placeholder="Please type your message here..."
                      name="message"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                      onKeyUp={formik.handleBlur}
                      maxLength={150}
                      invalid={formik.touched.message && formik.errors.message}
                      errorMessage={formik.errors.message}
                      rows={3}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className="submitForm">
                      <LoaderButton
                         isLoading={busy}
                         btnTitle={'Send Message'}
                         type='submit'
                         disabled={!(formik.isValid && formik.dirty)}
                          />
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </section>
    </Fragment>
  );
};
export default ContactUs;

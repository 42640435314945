import React, { useEffect, useState } from "react";
import { Spinner, Container } from "reactstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import { BiX, BiPlus } from "react-icons/bi";
import AxiosInstance from "../../config/AxiosInstance";
import CustomButton from "../../Components/CustomButton/CustomButton";
import Slider from "../../Components/Slider/Slider";
import OVERVIEWIMAGE from "../../Assets/Images/image1.png";
import KEYFEATURESIMAGE from "../../Assets/Images/image3.png";
import LOGO from "../../Assets/Images/logo.png";
import "./Home.scss";

const Home = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [bannerDetails, setBannerDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [activeFaq, setActiveFaq] = useState(null);
  const [faqList, setFaqList] = useState([]);
  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };
  const handleFaqChange = (index) => (event, activeFaq) => {
    setActiveFaq(activeFaq ? index : null);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const getHomeDetails = async () => {
      try {
        setTimeout(async () => {
          const res = await AxiosInstance.get("/web-management/get-title");
          if (res && res?.status === 200) {
            setBannerDetails(res?.data?.r);
            setIsLoading(false);
          }
        }, 2000);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    };
    getHomeDetails();
  }, []);
  useEffect(() => {
    const getFaqList = async () => {
      try {
        setTimeout(async () => {
          const res = await AxiosInstance.get(
            "/web-management/get-faq?count=0"
          );
          if (res && res?.status === 200) {
            setFaqList(res?.data?.r);
          }
        }, 2000);
      } catch (error) {
        console.log("error", error);
      }
    };
    getFaqList();
  }, []);
  return (
    <div className="home">
      <div className="bannerSection">
        {isLoading ? (
          <Spinner size="lg" color="light" />
        ) : (
          <div className="heroSection">
            <div className="bannerWrapper">
              {bannerDetails?.active_media === 1 ? (
                <img
                  src={bannerDetails?.image}
                  alt="overview"
                  className="bannerImage"
                  loading="lazy"
                />
              ) : bannerDetails?.active_media === 2 ? (
                <video
                  autoPlay
                  muted
                  loop
                  className={`bgVideo ${videoLoaded ? "loaded" : "loading"}`}
                  onLoadedData={handleVideoLoad}
                >
                  <source src={bannerDetails?.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : null}
            </div>
            <Container fluid>
              <div className="heroCard">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5} >
                    <div className="heroContent">
                      <h3 className="title" data-aos="fade-top">
                        {bannerDetails?.title}
                      </h3>
                      <p className="subTitle" data-aos="fade-top">
                        {bannerDetails?.description}
                      </p>
                      <CustomButton
                        type="button"
                        btnTitle="Get Started"
                        onClick={() =>
                          window.open("https://portal.roavebox.com", "_blank")
                        }
                        data-aos="fade-top"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
        )}
      </div>
      <div className="overView">
      <Container fluid>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={7} xxl={7}>
            <div className="overViewPicture">
              <img
                srcSet={OVERVIEWIMAGE}
                alt="overview"
                data-aos="fade-top"
                loading="lazy"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={5} xxl={5}>
            <div className="overViewContent">
              <h3 className="title" data-aos="fade-down">
                RoaveBox Overview
              </h3>
              <p className="subTitle" data-aos="fade-up">
                RoaveBox is revolutionising the traditional moving and storage
                experience with its innovative, user-friendly design. Aimed at
                simplifying the packing process, RoaveBox offers a perfect blend
                of convenience, efficiency, and sustainability.
              </p>
            </div>
          </Grid>
        </Grid>
        </Container>
      </div>
      <div className="packSmartSection">
        <Container fluid>
          <div className="packSmartContent">
            <h3 className="title" data-aos="fade-up">
              Pack Smart, Move Easy
            </h3>
            <div className="packSmartItem">
              <h6 className="subTitle" data-aos="fade-down">
                🚀 Quick-Fold Tech:{" "}
              </h6>
              <p className="descreption" data-aos="fade-up">
                Assemble in seconds. No fuss, no stress.
              </p>
            </div>
            <div className="packSmartItem">
              <h6 className="subTitle" data-aos="fade-down">
                🌿 Eco-Friendly:{" "}
              </h6>
              <p className="descreption" data-aos="fade-up">
                Made with sustainable materials because we care about the
                planet.
              </p>
            </div>
            <div className="packSmartItem">
              <h6 className="subTitle" data-aos="fade-down">
                💪 Durable & Reusable:
              </h6>
              <p className="descreption" data-aos="fade-up">
                Built strong for repeated use. Goodbye, one-time use boxes!
              </p>
            </div>
            <div className="packSmartItem">
              <h6 className="subTitle" data-aos="fade-down">
                🎨 Customisable:
              </h6>
              <p className="descreption" data-aos="fade-up">
                Your box, your style. Make it yours.
              </p>
            </div>
            <div className="packSmartItem">
              <h6 className="subTitle" data-aos="fade-down">
                📱 InstaWorthy:
              </h6>
              <p className="descreption" data-aos="fade-up">
                Not just a box - it's a statement. Share your move with
                [#RoaveAndRave].
              </p>
            </div>
            <div className="packSmartItem">
              <h6 className="subTitle" data-aos="fade-down">
                🎓 Students on the move:
              </h6>
              <p className="descreption" data-aos="fade-up">
                Join the [Roaveolution] - Pack smarter, live better
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="keyFeaturesSection">
        <Container fluid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={7} xl={7} xxl={7}>
              <div className="keyImageLeftCard">
                <div className="keyImageLeftCardInner">
                  <img
                    srcSet={KEYFEATURESIMAGE}
                    alt="keyfeatures"
                    data-aos="fade-top"
                    loading="lazy"
                  />
                </div>
              </div>
            </Grid>
            <Grid item sm={12} md={6} lg={4} xl={4} xxl={4}>
              <div className="keyFeaturesContnt">
                <h3 className="title" data-aos="flip-down">
                  Key Features
                </h3>
                <ul>
                  <li>
                    <h4 className="subTitle" data-aos="flip-up">
                      Quick-Fold Mechanism:{" "}
                    </h4>
                    <p className="descreption" data-aos="flip-down">
                      Our patented design allows for effortless box assembly in
                      seconds, reducing packing time by over 50%.Ergonomic
                      Design: Thoughtfully crafted to minimise physical strain,
                      making it easier to pack, lift, and transport.
                    </p>
                  </li>
                  <li>
                    <h4 className="subTitle" data-aos="fade-top">
                      High-Quality Material:{" "}
                    </h4>
                    <p className="descreption" data-aos="fade-top">
                      Made from sustainable yet durable materials that can
                      withstand the demands of moving or shipping.
                    </p>
                  </li>
                  <li>
                    <h4 className="subTitle" data-aos="fade-top">
                      Cost-Effective:{" "}
                    </h4>
                    <p className="descreption" data-aos="fade-top">
                      Competitively priced with traditional boxes but offers far
                      more convenience and efficiency.
                    </p>
                  </li>
                  <li>
                    <h4 className="subTitle" data-aos="fade-top">
                      Reusable and Recyclable:
                    </h4>
                    <p className="descreption" data-aos="fade-top">
                      Built to last for multiple uses and can be easily
                      Recyclable, making it a sustainable choice for the
                      environmentally conscious consumer.
                    </p>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="benifitsSection">
        <h3 className="title" data-aos="fade-top">
          Benefits
        </h3>
        <div className="benifitsConteiner">
          <Container fluid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <div className="benifitBox" data-aos="fade-top">
                  <p className="subTitle">For Businesses:</p>
                  <p className="desc">
                    Streamline your shipping process, reduce labour costs, and
                    improve customer satisfaction.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <div className="benifitBox" data-aos="fade-top">
                  <p className="subTitle">For Businesses:</p>
                  <p className="desc">
                    Streamline your shipping process, reduce labour costs, and
                    improve customer satisfaction.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <div className="benifitBox" data-aos="fade-top">
                  <p className="subTitle">For Businesses:</p>
                  <p className="desc">
                    Streamline your shipping process, reduce labour costs, and
                    improve customer satisfaction.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="benifitSLider">
            <div className="imageBenifitSlider" data-aos="fade-top">
              <Slider />
            </div>
          </div>
        </div>
      </div>
      <div className="reaveBoxSection">
        <div className="raveBoxCenter">
          <img
            srcSet={LOGO}
            alt="sample_image"
            width={"296px"}
            data-aos="fade-top"
            loading="lazy"
          />
          <h6 data-aos="fade-top">
            🌍 Join the [Roaveolution] - Pack smarter, live better.
          </h6>
          <CustomButton
            variant="outlined"
            btnTitle="Join Now"
            data-aos="fade-top"
            onClick={() => window.open("https://portal.roavebox.com", "_blank")}
          />
        </div>
      </div>
      <div className="faqSection">
        <Container fluid>
          <div className="faqList">
            {faqList?.length > 0 &&
              faqList?.map((elem, index) => {
                return (
                  <Accordion
                    key={index}
                    expanded={activeFaq === index}
                    onChange={handleFaqChange(index)}
                  >
                    <AccordionSummary
                      expandIcon={
                        activeFaq === index ? (
                          <div className="customCloseIcon">
                            <BiX color="#00000" />
                          </div>
                        ) : (
                          <div className="customPlusIcon">
                            <BiPlus color="#ffffff" />
                          </div>
                        )
                      }
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                    >
                      {elem?.question}
                    </AccordionSummary>
                    <AccordionDetails>{elem?.answer}</AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Home;

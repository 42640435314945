import { FormFeedback, Input } from "reactstrap";
import'./CustomTextField.scss';
 const CustomTextField = (props) => {
  return (
    <>
     <Input
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        onKeyUp={props.onKeyUp}
        maxLength={props.maxLength}
        invalid={props.invalid}
        rows={props.rows}
        className={props.invalid ? "customTextField redBorder" : "customTextField"}
      />

      
      {props.invalid && <FormFeedback type="invalid">{props.errorMessage}</FormFeedback>}</>
  );
};
export default CustomTextField;

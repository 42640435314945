import React, { Fragment } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "./NotFoundPage.scss";
const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="pageNotFound">
        <Container fluid>
          <Row>
            <Col xs={12} md={12} xl={12} xxl={12} data-aos="fade-right">
              <Card>
                <CardBody>
                  <div className="pageNotFoundContent">
                    <h1>404</h1>
                    <h2>Not Found</h2>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <Button
                      type="button"
                      className="viewCard"
                      onClick={() =>
                        navigate("/home")
                      }
                    >
                      Back To Home
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default NotFoundPage;

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCoverflow,
  Pagination,
  Autoplay,
  Navigation,
} from "swiper/modules";
import { Box, Typography } from "@mui/material";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import AxiosInstance from "../../config/AxiosInstance";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./Slider.scss";
const Slider = () => {
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const getProductList = async () => {
    await AxiosInstance.get("/product/get-all")
      .then((res) => {
        setProductList(res?.data?.r);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    
    getProductList()

  }, []);
  return (
    <>
      <Box className="customSLider">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          loop={true}
          initialSlide={1}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={false}
          modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
          className="mySwiper"
          navigation={{
            nextEl: ".custom-next-button",
            prevEl: ".custom-prev-button",
          }}
        >
          {productList?.length > 2 &&
            productList?.map((elem, index) => (
              <SwiperSlide
                style={{
                  padding: "10px",
                }}
                key={index}
              >
                <img
                  src={elem?.cover_img}
                  alt={`Slide ${index}`}
                  style={{
                    width: "100%",
                    margin: "auto",
                  
                  }}
                />
                <Box mt={2} className={`sliderBox`}>
                  <Box className="contentBox">
                    <Typography variant="h3">{elem?.name}</Typography>
                    <Typography variant="h6">
                      {elem?.description.substring(0, 70)}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CustomButton
                        btnTitle="Get started"
                        onClick={() =>
                          navigate("/product/product-details", {
                            state: {
                              productId: elem?.id,
                            },
                          })
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="custom-prev-button">
        <BsArrowLeft />
        </div>
        <div className="custom-next-button">
          <BsArrowRight />
        </div>
      </Box>
    </>
  );
};

export default Slider;
import React from "react";
import { Button } from "reactstrap";
import { BsArrowRight } from "react-icons/bs";
import "./CustomButton.scss";
const CustomButton = (props) => {
  const { onClick, btnTitle, type, style } = props;
  return (
    <Button className={`customBtn`} onClick={onClick} type={type} style={style}>
      {btnTitle}
      <div className="icon">
        <BsArrowRight />
      </div>
    </Button>
  );
};

export default CustomButton;

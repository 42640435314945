import React from "react";
import { Button, Spinner } from "reactstrap";
import "./LoaderButton.scss";

const LoaderButton = (props) => {
  const { onClick, btnTitle, type, isLoading, disabled } = props;

  return (
    <Button
      className={`customBtn`}
      onClick={onClick}
      type={type}
      disabled={isLoading || disabled}
    >
      {isLoading ? <Spinner size="sm" color="light" /> : btnTitle}
    </Button>
  );
};

export default LoaderButton;

import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import AxiosInstance from '../../config/AxiosInstance';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
    const [privacyPolicyList, setPrivacyPolicyList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        const getProduct = async () => {
            try {
                const res = await AxiosInstance.get("/web-management/get-general-docs", {
                    params: { count: 0 },
                });
                if (res && res?.data?.s === 1) {
                    setPrivacyPolicyList(res?.data?.r);
                }
            } catch (error) {
                console.log("error", error);
            } finally {
                setIsLoading(false);
            }
        };
        getProduct();
    }, []);
    return (
        <Fragment>
            <div className='policyPolicy'>
            <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className='mainTitleSection'>
                                <h5>Privacy Policy</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        { isLoading 
                        ? <Col xs={12}>
                            <div className='isLoading'>
                                <Spinner size="lg" color="light"/>
                            </div>
                        </Col> 
                        : privacyPolicyList?.length > 0
                            ? privacyPolicyList?.map((item, index) => {
                                return (
                                    <Col xs={12} md={12} xl={12} xxl={12} key={index} data-aos="fade-right">
                                        <Card>
                                            <CardBody>
                                            <div dangerouslySetInnerHTML={{ __html: item?.privacy_policy }} style={{ color: 'white' }} />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            })
                            : <Col xs={12}>
                                <div className='notDataFound'>
                                    <h6>No Data Found</h6>
                                </div>
                            </Col>}
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}

export default PrivacyPolicy;

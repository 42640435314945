import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { CiMail } from "react-icons/ci";
import { IoIosPhonePortrait } from "react-icons/io";
import { LuMapPin } from "react-icons/lu";
import AxiosInstance from "../../config/AxiosInstance";
import LOGO from "../../Assets/Images/logo.png";
import FACEBOOK from "../../Assets/Images/Facebook.png";
import INSTAGRAM from "../../Assets/Images/Instagram.png";
import TWITTER from "../../Assets/Images/Twitter.png";
import "./Footer.scss";
const Footer = () => {
  const [socialDetails, setSocialDetails] = useState({});
  const navLinks = [
    { path: "/home", text: "Home" },
    { path: "/product", text: "Product Gallery" },
    { path: "/contact-us", text: "Contact Us" },
  ];
  const navLinks1 = [
    { path: "/values", text: "Values" },
    { path: "/terms-conditions", text: "Terms & Conditions" },
    { path: "/privacy-policy", text: "Privacy Policy" },
    { path: "/code-of-conduct", text: "Code of Conduct" },
  ];
  useEffect(() => {

    
    const getContactDetails = async () => {
      await AxiosInstance.get("/web-management/get-contact?id=1")
        .then((res) => {
          if (res?.data?.s === 1) {
            setSocialDetails(res?.data?.r);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    getContactDetails();
  }, []);




  return (
    <footer className="footer">
      <Container fluid>
        <div className="footerRow">
          <div className="footerOne">
            <div className="footerOneRow">
              <div className="footerOneColumnLeft">
                <div className="brandLogo">
                  <img src={LOGO} className="logo" alt="logo" />
                </div>
              </div>
              <div className="footerOneColumnCenter">
                <ul>
                  {navLinks.map((link, index) => (
                    <li key={index}>
                      <NavLink
                        to={link.path}
                        activeClassName="navActive"
                        className="navLinks"
                      >
                        {link.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="footerOneColumnRight">
                <ul>
                  <li>
                    <Link
                      eventkey="facebook"
                      to={socialDetails?.facebook}
                      className={"navLinks"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={FACEBOOK} alt={"FACEBOOK"} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      eventkey="instagram"
                      to={socialDetails?.instagram}
                      className={"navLinks"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={INSTAGRAM} alt={"INSTAGRAM"} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      eventkey="twitter"
                      to={socialDetails?.twitter}
                      className={"navLinks"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={TWITTER} alt={"TWITTER"} />
                    </Link>
                  </li>
                  {/* <li>
                                        <Link
                                            eventkey="linkdin"
                                            to={socialDetails?.linkedin}
                                            className={"navLinks"}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={LINKDIN} alt={"LINKDIN"} />
                                        </Link>
                                    </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="footerTwo">
            <ul>
              {navLinks1.map((link, index) => (
                <li key={index}>
                  <NavLink
                    to={link.path}
                    activeClassName="navActive"
                    className="navLinks"
                  >
                    {link.text}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="footerThree">
            <Row>
              <Col xs={12} md={4}>
                <div className="information">
                  <ul>
                    <li>
                      <LuMapPin fontSize={"32px"} />
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          `${socialDetails?.address}`
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{socialDetails?.address}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="information mail">
                  <ul>
                    <li>
                      <CiMail fontSize={"22px"} />
                      <a href={`mailto:${socialDetails?.email}`}>
                        {/* Add your email address here */}
                        <span>{socialDetails?.email}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="information number">
                  <ul>
                    <li>
                      <IoIosPhonePortrait fontSize={"24px"} />
                      <a href={`tel:${socialDetails?.phno}`}>
                        {/* Add your phone number here */}
                        <span>{socialDetails?.phno}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </footer>
  );
};
export default Footer;

import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.scss";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import ProductGallery from "./Pages/Product/ProductGallery/ProductGallery";
import ProductDetails from "./Pages/Product/ProductDetails/ProductDetails";
import ContactUs from "./Pages/ContactUs/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./Pages/Condition/TermsConditions";
import Values from "./Pages/Values/Values";
import CodeOfConduct from "./Pages/CodeOfConduct/CodeOfConduct";
import Home from "./Pages/Home/Home";
import NotFoundPage from "./Components/NotFoundPage/NotFoundPage";

const App = () => {
  const location = useLocation();
  const [isValidRoute, setIsValidRoute] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    const isValid = [
      "/",
      "/home",
      "/product",
      "/product/product-details",
      "/contact-us",
      "/privacy-policy",
      "/terms-conditions",
      "/values",
      "/code-of-conduct"
    ].includes(location.pathname);
    setIsValidRoute(isValid);
  }, [location]);

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="layoutWrapper">
        {isValidRoute && <Header />}
        <div className="contentWrapper">
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/product" element={<ProductGallery />}></Route>
            <Route
              path="/product/product-details"
              element={<ProductDetails />}
            ></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route
              path="/terms-conditions"
              element={<TermsConditions />}
            ></Route>
            <Route path="/values" element={<Values />}></Route>
            <Route path="/code-of-conduct" element={<CodeOfConduct />}></Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        {isValidRoute && <Footer />}
      </div>
 
    </div>
  );
};

export default App;

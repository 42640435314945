import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { useInView } from "react-intersection-observer";
import AxiosInstance from "../../../config/AxiosInstance";
import "./ProductGallery.scss";
const ProductGallery = () => {
  let dataLength = 0;
  const navigate = useNavigate();
  const { ref, inView } = useInView();
  const [moreData, setMoreData] = useState(false);
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  
  const getProduct = async (count = 0) => {
    try {
      setIsLoading(true);
      const res = await AxiosInstance.get("/product/get-all", {
        params: { count: 0 },
      });
      if (res && res?.data?.s === 1) {
        setMoreData(true);
        if (count !== 0) {
          setProductList((prev) =>
            prev.length ? [...prev, ...res?.data?.r] : res?.data?.r
          );
        } else {
          setProductList(res.data.r);
        }
      } else {
        setMoreData(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (productList.length > 28 && inView && moreData) {
      //eslint-disable-next-line
      dataLength = productList?.length;
      getProduct(dataLength);
    }
  }, [inView]);
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);
  useEffect(() => {
    getProduct();
  }, []);
  return (
    <Fragment>
      <div className="productGallery">
      <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="mainTitleSection">
                <h5>Watch Our Gallery</h5>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit semper
                  dalar elementum tempus hac tellus libero accumsan.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            {isLoading ? (
              <Col xs={12}>
                <div className="isLoading">
                  <Spinner size="lg" color="light" />
                </div>
              </Col>
            ) : productList?.length > 0 ? (
              productList?.map((item, index) => {
                return (
                  <Col
                    xs={12}
                    md={4}
                    xl={3}
                    xxl={3}
                    key={index}
                    data-aos="fade-top"
                    className="mb-3"
                  >
                    <Card>
                      <CardBody>
                        <div className="productImage">
                          <img
                            src={item?.cover_img}
                            className="img-fuild"
                            alt="product"
                            loading="lazy"
                          />
                        </div>
                        <p>{item?.name}</p>
                        <div className="productViewButton">
                          <Button
                            type="button"
                            className="viewCard"
                            onClick={() =>
                              navigate("/product/product-details", {
                                state: {
                                  productId: item?.id,
                                },
                              })
                            }
                          >
                            View Product
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <Col xs={12}>
                <div className="notDataFound">
                  <h6>No Data Found</h6>
                </div>
              </Col>
            )}
            {isLoading && (
              <Col xs={12}>
                <div className="isLoading" ref={ref}>
                  <Spinner size="lg" color="light" />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default ProductGallery;
